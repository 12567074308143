import React, { Component } from "react";
import { Card, AppBar, BottomNavigation } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import './Game.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import MetaTags from 'react-meta-tags';

class PeulaPage extends Component {
    constructor() {
        super();
        this.state = {
            peula: "not",
            user: "not"
        }
    }

    render() {
        var path = window.location.pathname;
        var gameId = path.toString().substring(6, 100);
        var items = "not set";

        return (
            <div>
                <MetaTags>
                    <title>{this.state.peula == "not" ? "קווה קווה" : this.state.peula.title}</title>
                    <meta name="description" content="שיתפו אותך בפעולה שהועלתה לאפליקציית קווה קווה. \n חפשו קווה קווה בחנות האפליקציות לעוד משחקים ופעולות" />
                    <meta property="og:description" content="שיתפו אותך במשחק שהועלה לאפליקציית קווה קווה. \n חפשו קווה קווה בחנות האפליקציות לעוד משחקים ופעולות"/>
                    <meta property="og:title" content={this.state.peula == "not" ? "קווה קווה" : this.state.peula.title} />
                    <meta property="og:image" content={process.env.PUBLIC_URL + '/logo.png'} />
                    <meta property="og:url" content={window.location.toString()} />
                    <meta property="og:type" content="article" />
                    <meta property="og:locale" content="he_il" />
                </MetaTags>
                <AppBar position="sticky" dir='rtl' style={{ background: '#66B7FF' }}>
                    <a href="/"><img src={process.env.PUBLIC_URL + '/logo_txt_b.png'} style={{ height: 35, width: 150, padding: 15 }} /></a>
                </AppBar>
                <div>
                    <Card style={{ margin: 15, padding: 10, direction: "rtl" }}>
                        {
                            this.state.peula == "not" ? <Loader type="Triangle" color="#444444" height={80} width="100%" />
                                :
                                <div>
                                    <h2 style={{ direction: "rtl" }}>{this.state.peula.title}</h2>
                                    <p style={{ direction: "rtl" }}>יוצר:
                                        {this.state.user == "not" ? <a>טוען...</a> : <a style={{ paddingRight: 5 }}>{this.state.user.nickname}</a>}
                                    </p>
                                    <p style={{ direction: "rtl" }}>תאריך פרסום:
                                        {this.state.user == "not" ? <a>טוען...</a> : <a style={{ paddingRight: 5 }}>
                                            {(new Date(this.state.peula.time)).getDay().toString() + "." + (new Date(this.state.peula.time)).getMonth().toString() + "." + (new Date(this.state.peula.time)).getYear().toString()}
                                        </a>}
                                    </p>
                                    <h3>מטרות</h3>
                                    <Markdown>
                                        {this.state.peula.targets}
                                    </Markdown>
                                    <h3>מהלך הפעולה</h3>
                                    <Markdown>
                                        {this.state.peula.content}
                                    </Markdown>
                                    <h3>רשמ"ץ</h3>
                                    {
                                        (this.state.peula.supply_items != null && this.state.peula.supply_items != "") ?
                                            <a>
                                                {
                                                    this.state.peula.supply_items.split("\n").map((value, index) => {
                                                        return <li key={index}>{this.state.peula.supply_counts.split("\n")[index] + ": " + value}</li>
                                                    })
                                                }
                                            </a> : <p>לא פורסם</p>
                                    }

                                </div>
                        }
                    </Card>
                </div>
                <div className="bottomDiv">
                    <a href="/">
                        <div className="bottomText">
                            לא מה שחיפשתם? הורידו את אפליקציית קווה קווה לעוד אלפי משחקים ופעולות
                    </div>
                    </a>
                </div>

            </div>
        )
    }
    componentDidMount() {
        var getPeulaUrl = "https://cors-anywhere.herokuapp.com/https://boinxi.pythonanywhere.com/peulas_in_string/" + window.location.pathname.toString().substring(6, 100);
        fetch(getPeulaUrl).then(response => response.json())
            .then(response => {
                this.setState({
                    peula: response[0],
                })
                var getUserUrl = "https://cors-anywhere.herokuapp.com/https://boinxi.pythonanywhere.com/user/" + this.state.peula.user_id;
                fetch(getUserUrl).then(res => res.json())
                    .then(res => {
                        this.setState({
                            user: res,
                        })

                    })

            })
    }

}

export default PeulaPage;