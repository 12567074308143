import React from 'react';
import logo from './logo.svg';
import './App.css';
import { HashRouter, Route, Switch} from 'react-router-dom';
import GamePage from './components/Game'
import PeulaPage from './components/Peula'
import Home from './components/Home'
import FOF from './components/FOF'
import AddPeula from './components/AddPeula'

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/game/:id"} exact component={GamePage} />
        <Route path={"/peula/:id"} exact component={PeulaPage} />
        <Route path={"/add/:id"} exact component={AddPeula} />
      </Switch>
    </HashRouter>
  );
}

export default App;
