import React, { Component } from "react";
import { TextField, Select, MenuItem, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { isAndroid, isIOS } from 'react-device-detect';
import './AddPeula.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { create } from "jss";
import rtl from "jss-rtl";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import { JssProvider } from 'react-jss'
import FaceIcon from '@material-ui/icons/Face';
import TimerIcon from '@material-ui/icons/Timer';
import GroupIcon from '@material-ui/icons/Group';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const generateClassName = createGenerateClassName();

function RTL(props) {
    return (
        <JssProvider jss={jss} generateClassName={generateClassName}>
            {props.children}
        </JssProvider>
    );
}

class AddPeula extends Component {

    constructor() {
        super();
        this.state = {
            title: "",
            kidsCount: 1,
            durationMins: 0,
            durationHours: 0,
            peulaClass: 7,
            targets: "<ul><li>החניך</li></ul>",
            content: "<h2>פעולת</h2>",
            errorMsg: "",
            errorDialogOpen: false,
            finishDialogOpen: false,
            posting: false,
            done: false,
            errorAlertOpen:false,
        }
    }
    verifyContent() {
        var titleOK = this.state.title.length > 2;
        var targetsOK = this.state.targets.length > 25;
        var contentOK = this.state.content.length > 25;
        var timeOK = this.state.durationHours != 0 || this.state.durationMins != 0;
        this.state.errorMsg = [];
        if (!titleOK) {
            this.state.errorMsg.push(<DialogContentText id="alert-dialog-description" style={{ textAlign: 'right' }}>{"הכותרת לא מספיק ארוכה"}</DialogContentText>)
        }
        if (!targetsOK) {
            this.state.errorMsg.push(<DialogContentText id="alert-dialog-description" style={{ textAlign: 'right' }}>{"המטרות לא מספיק ארוכות"}</DialogContentText>)
        }
        if (!contentOK) {
            this.state.errorMsg.push(<DialogContentText id="alert-dialog-description" style={{ textAlign: 'right' }}>{"מהלך הפעולה לא מספיק ארוך"}</DialogContentText>)
        }
        if (!timeOK) {
            this.state.errorMsg.push(<DialogContentText id="alert-dialog-description" style={{ textAlign: 'right' }}>{"זמן לא יכול להיות 0:00"}</DialogContentText>)
        }
        this.state.errorMsg.push(<DialogContentText id="alert-dialog-description" style={{ textAlign: 'right' }}>{"כמעט עשית את זה... לא להתייאש"}</DialogContentText>)
        return targetsOK && titleOK && contentOK && timeOK;
    }

    render() {
        var path = window.location.pathname;
        var pathComponents = path.toString().split("/");
        var userId = pathComponents[pathComponents.length - 1];
        var items = "not set";

        return (

            <div>
                <div className="bodyMargin">
                    <div>
                        <RTL>
                            <TextField
                                id="standard-basic"
                                placeholder="כותרת הפעולה"
                                className="titleInput"
                                dir="rtl"
                                inputProps={{ style: { fontSize: 25 } }} // font size of input text
                                onChange={(event) => {
                                    this.state.title = event.target.value;
                                }}
                            />
                        </RTL>
                    </div>
                    <p></p>
                    <div>
                        <div>
                            <a>
                                זמן
                            </a> <a> </a>
                            <TimerIcon />
                        </div>

                        <RTL>
                            <TextField
                                id="standard-basic"
                                placeholder="1"
                                className="titleInputShort"
                                dir="rtl"
                                type="number"
                                InputProps={{ inputProps: { min: 1, max: 10 } }}
                                onChange={(event) => {
                                    this.state.durationHours = parseInt(event.target.value);
                                }}
                            />
                        </RTL>
                        <a>:</a>
                        <RTL>
                            <TextField
                                id="standard-basic"
                                placeholder="00"
                                className="titleInputShort"
                                dir="rtl"
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 59 } }}
                                onChange={(event) => {
                                    this.state.durationMins = parseInt(event.target.value);
                                }}
                            />
                        </RTL>
                    </div>
                    <p></p>
                    <div>
                        <div>
                            <a>
                                כמות חניכים
                            </a> <a> </a>
                            <GroupIcon />
                        </div>
                        <RTL>
                            <TextField
                                id="standard-basic"
                                placeholder="0"
                                className="titleInputShort"
                                dir="rtl"
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 50 } }}
                                onChange={(event) => {
                                    this.state.kidsCount = parseInt(event.target.value);
                                }}
                            />
                        </RTL>
                    </div>
                    <p></p>
                    <RTL>
                        <div>
                            <a>
                                שכבה
                            </a><a> </a>
                            <FaceIcon />
                        </div>
                        <Select
                            className="SelectInput"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.peulaClass}
                            onChange={(event) => {
                                this.setState({
                                    peulaClass: event.target.value
                                })
                            }}
                        >
                            <MenuItem value={0}>לכולם</MenuItem>
                            <MenuItem value={1}>א</MenuItem>
                            <MenuItem value={2}>ב</MenuItem>
                            <MenuItem value={3}>ג</MenuItem>
                            <MenuItem value={4}>ד </MenuItem>
                            <MenuItem value={5}>ה</MenuItem>
                            <MenuItem value={6}>ו</MenuItem>
                            <MenuItem value={7}>ז</MenuItem>
                            <MenuItem value={8}>ח</MenuItem>
                            <MenuItem value={9}>ט</MenuItem>
                            <MenuItem value={10}>י</MenuItem>
                            <MenuItem value={11}>יא</MenuItem>
                            <MenuItem value={12}>יב</MenuItem>
                            <MenuItem value={13}>מעל יב</MenuItem>
                        </Select>
                    </RTL>
                    <h2 className="header1">מטרות</h2>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.state.targets}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            this.state.targets = data;
                        }}
                        config={{
                            language: 'he',
                            toolbar: ['Essentials', 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'Indent', 'Link', 'List', 'PasteFromOffice', 'Table', 'TableToolbar'],
                        }}
                    />
                    <h2 className="header1">מהלך הפעולה</h2>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.state.content}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            this.state.content = data;
                        }}
                        config={{
                            language: 'he',
                            toolbar: ['Essentials', 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'Indent', 'Link', 'List', 'PasteFromOffice', 'Table', 'TableToolbar'],
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 25, marginRight: 0, fontSize: 25 }}
                        onClick={() => this.onUploadClick()}
                    >
                        פרסם
                        </Button>

                </div>
                <Dialog
                    open={this.state.errorDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'right' }}>{"לא מגניב"}</DialogTitle>
                    <DialogContent>
                        {this.state.errorMsg}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ errorDialogOpen: false }) }} color="primary" autoFocus>
                            לא יקרה שוב
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.finishDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {this.state.posting ? <CircularProgress style={{ margin: 50, }} /> :
                        <a>
                            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'right' }}>{"בדוק סיימת?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {" אי אפשר לשנות תוכן אחרי פרסום... אז כדאי להיות בטוח."}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { this.startPosting() }} color="primary" autoFocus>
                                    בטח נו
                            </Button>
                                <Button onClick={() => this.setState({ finishDialogOpen: false })} color="primary">
                                    שיט לא
                            </Button>

                            </DialogActions>
                        </a>
                    }
                </Dialog>
                <Backdrop open={this.state.posting} style={{ color: "#bbb", zIndex: 9999999999 }}>
                    {this.state.done ? <Card style={{ textAlign: "right", padding: 50 }}><h1>סיימת</h1><h3>אפשר לסגור את החלון</h3></Card> : <CircularProgress color="inherit" />}
                </Backdrop>
                <Snackbar open={this.state.errorAlertOpen} autoHideDuration={6000} onClose={() => {this.setState({errorAlertOpen: false})}}>
                    <MuiAlert elevation={6} variant="filled" onClose={() => {this.setState({errorAlertOpen: false})}} severity="error" style={{ textAlign: "right"}}>
                        מביך, היתה בעיה... נסו שוב בבקשה
                    </MuiAlert>
                </Snackbar>

            </div>
        );
    }
    onUploadClick() {
        if (!this.verifyContent()) {
        // if (false) {
            this.setState({ errorDialogOpen: true })
        } else {
            this.showFinishDialog();
        }
    }

    showFinishDialog() {
        this.setState({ finishDialogOpen: true })
    }
    startPosting() {
        this.setState({ posting: true, finishDialogOpen: false });

        var path = window.location.pathname;
        var pathComponents = path.toString().split("/");
        var userId = pathComponents[pathComponents.length - 1];
        var duration = ((this.state.durationHours*60) + this.state.durationMins)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                title: this.state.title,
                content: this.state.content,
                user_id: userId,
                age: this.state.peulaClass,
                kids: this.state.kidsCount,
                duration: duration,
                targets: this.state.targets,
                supply_items: "",
                supply_counts: "",
                content_delta: "",
                targets_delta: "",
            })
        };
        fetch('https://cors-anywhere.herokuapp.com/https://boinxi.pythonanywhere.com/insert/peula', requestOptions).then((response) => {
            console.log(response)
            console.log(response['ok'])
            this.setState({ done: true });
        }).catch(error => {
            this.setState({
                errorAlertOpen: true,
                posting: false, 
                finishDialogOpen: false 
            });
            console.error('There was an error!', error);
        });


    }

    componentDidMount() {
        // var getPeulaUrl = "https://cors-anywhere.herokuapp.com/https://boinxi.pythonanywhere.com/games_in_string/" + window.location.pathname.toString().substring(6, 100);
        // fetch(getPeulaUrl).then(response => response.json())
        //     .then(response => {
        //         this.setState({
        //             g: response[0],
        //         })
        //         var getUserUrl = "https://cors-anywhere.herokuapp.com/https://boinxi.pythonanywhere.com/user/" + this.state.g.user_id;
        //         fetch(getUserUrl).then(res => res.json())
        //             .then(res => {
        //                 this.setState({
        //                     u: res,
        //                 })

        //             })

        //     })
    }
}
export default AddPeula;