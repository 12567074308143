import React, { Component } from 'react'
import './Home.css'
import { Card, Button} from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { withRouter } from 'react-router-dom'
import MetaTags from 'react-meta-tags';

export default class Home extends Component {

    render() {
        const responsive = {
            desktop: {
                breakpoint: {
                    max: 3000,
                    min: 1024
                },
                items: 3,
                partialVisibilityGutter: 40
            },
            mobile: {
                breakpoint: {
                    max: 464,
                    min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
            },
            tablet: {
                breakpoint: {
                    max: 1024,
                    min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
            }
        };

        return (
            <div className="allPage" style={{ minHeight: "100%" }}>
                <MetaTags>
                    <title>{"קווה קווה"}</title>
                    <meta name="description" content="חפשו קווה קווה בחנות האפליקציות ומצאו משחקים ופעולות במאגר הענקי שלנו" />
                    <meta property="go:description" content="חפשו קווה קווה בחנות האפליקציות ומצאו משחקים ופעולות במאגר הענקי שלנו" />
                    <meta property="og:title" content={"קווה קווה"} />
                    <meta property="og:image" content={process.env.PUBLIC_URL + '/logo.png'} />
                    <meta property="og:url" content={window.location.toString()} />
                    <meta property="og:type" content="article" />
                    <meta property="og:locale" content="he_il" />
                </MetaTags>

                <div className="topDiv">
                    <a href="/"><img src={process.env.PUBLIC_URL + '/logo_txt_b.png'} style={{ height: 70, width: 310, paddingTop: 65 }} /></a>
                </div>
                <p className="title" >
                    מאגר הפעולות והמשחקים הגדול בישראל לחברי תנועות הנוער ומדריכים
               </p>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: 10 }} />
                    <div className="downloadCard">
                        <img src={process.env.PUBLIC_URL + '/google-play.png'} style={{ height: 70, width: 70, padding: 15 }} />
                    </div>
                    <div className="downloadCard">
                        <img src={process.env.PUBLIC_URL + '/app-store.png'} style={{ height: 70, width: 70, padding: 15 }} />
                    </div>
                    <div style={{ width: 10 }} />
                </div>
                <p className="middleText" >
                    מושלם לתנועות נוער, מפקדים, מדריכים, חונכים, מורים, גננות, רכזים וכל מי שרוצה להעביר פעולה או משחק ומחפש רעיונות
               </p>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    draggable
                    focusOnSelect={false}
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    <img src={process.env.PUBLIC_URL + '/screenshot.jpeg'} style={{ height: 420, width: 200, paddingLeft: 100, paddingRight:100, paddingTop:30 }} />
                    <img src={process.env.PUBLIC_URL + '/screenshot.jpeg'} style={{ height: 420, width: 200, paddingLeft: 100, paddingRight:100, paddingTop:30 }} />
                    <img src={process.env.PUBLIC_URL + '/screenshot.jpeg'} style={{ height: 420, width: 200, paddingLeft: 100, paddingRight:100, paddingTop:30 }} />
                    <img src={process.env.PUBLIC_URL + '/screenshot.jpeg'} style={{ height: 420, width: 200, paddingLeft: 100, paddingRight:100, paddingTop:30 }} />

                </Carousel>;

            </div>

        )
    }
}
