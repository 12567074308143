import React, { Component } from "react";
import { Card, AppBar, BottomNavigation } from '@material-ui/core';
import { isAndroid, isIOS } from 'react-device-detect';
import './Game.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";

class GamePage extends Component {

    constructor() {
        super();
        this.state = {
            g: "not",
            u: "not"
        }
    }

    render() {
        var path = window.location.pathname;
        var gameId = path.toString().substring(6, 100);
        var items = "not set";

        return (
            <div>
                <MetaTags>
                    <title>{this.state.g == "not" ? "קווה קווה" : this.state.g.title}</title>
                    <meta name="description" content="שיתפו אותך במשחק שהועלה לאפליקציית קווה קווה. \n חפשו קווה קווה בחנות האפליקציות לעוד משחקים ופעולות" />
                    <meta property="og:description" content="שיתפו אותך במשחק שהועלה לאפליקציית קווה קווה. \n חפשו קווה קווה בחנות האפליקציות לעוד משחקים ופעולות" />
                    <meta property="og:title" content={this.state.g == "not" ? "קווה קווה" : this.state.g.title} />
                    <meta property="og:image" content={process.env.PUBLIC_URL + '/logo.png'} />
                    <meta property="og:url" content={window.location.toString()} />
                    <meta property="og:type" content="article" />
                    <meta property="og:locale" content="he_il" />
                </MetaTags>
                <Helmet>
                    <title>{this.state.g == "not" ? "קווה קווה" : this.state.g.title}</title>
                    <meta name="description" content="שיתפו אותך במשחק שהועלה לאפליקציית קווה קווה. \n חפשו קווה קווה בחנות האפליקציות לעוד משחקים ופעולות" />
                    <meta property="og:description" content="שיתפו אותך במשחק שהועלה לאפליקציית קווה קווה. \n חפשו קווה קווה בחנות האפליקציות לעוד משחקים ופעולות" />
                    <meta property="og:title" content={this.state.g == "not" ? "קווה קווה" : this.state.g.title} />
                    <meta property="og:image" content={process.env.PUBLIC_URL + '/logo.png'} />
                    <meta property="og:url" content={window.location.toString()} />
                    <meta property="og:type" content="article" />
                    <meta property="og:locale" content="he_il" />
                </Helmet>

                <AppBar position="sticky" dir='rtl' style={{ background: '#66B7FF' }}>
                    <a href="/"><img src={process.env.PUBLIC_URL + '/logo_txt_b.png'} style={{ height: 30, width: 130, padding: 10 }} /></a>
                </AppBar>
                <div style={{ height: "700px" }}>
                    <Card style={{ margin: 50, padding: 10 }}>
                        {
                            this.state.g == "not" ? <Loader type="Triangle" color="#444444" height={80} width="100%" /> :
                                <div>
                                    <h2 style={{ direction: "rtl" }}>{this.state.g.title}</h2>
                                    <p style={{ direction: "rtl" }}>יוצר:
                                {this.state.u == "not" ? <a>טוען...</a> : <a style={{ paddingRight: 5 }}>{this.state.u.nickname}</a>}
                                    </p>
                                    <p style={{ direction: "rtl" }}>תאריך פרסום:
                                {this.state.u == "not" ? <a>טוען...</a> : <a style={{ paddingRight: 5 }}>
                                            {(new Date(this.state.g.time)).getDay().toString() + "." + (new Date(this.state.g.time)).getMonth().toString() + "." + (new Date(this.state.g.time)).getYear().toString()}
                                        </a>}
                                    </p>
                                    <p style={{ direction: "rtl" }}>{this.state.g.description}</p>
                                </div>
                        }
                    </Card>

                </div>
                <div className="bottomDiv">
                    <a href="/">
                        <div className="bottomText">
                            לא מה שחיפשתם? הורידו את אפליקציית קווה קווה לעוד אלפי משחקים ופעולות
                    </div>
                    </a>
                </div>

            </div>
        );
    }

    componentDidMount() {
        var getPeulaUrl = "https://cors-anywhere.herokuapp.com/https://boinxi.pythonanywhere.com/games_in_string/" + window.location.pathname.toString().substring(6, 100);
        fetch(getPeulaUrl).then(response => response.json())
            .then(response => {
                this.setState({
                    g: response[0],
                })
                var getUserUrl = "https://cors-anywhere.herokuapp.com/https://boinxi.pythonanywhere.com/user/" + this.state.g.user_id;
                fetch(getUserUrl).then(res => res.json())
                    .then(res => {
                        this.setState({
                            u: res,
                        })

                    })

            })
    }
}

export default GamePage;